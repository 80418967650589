<template>
  <Modal :open="isOpen">
    <template #content>
      <div>
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-danger/20 text-danger/80 text-2xl">
          <span class="fa fa-exclamation-triangle" />
        </div>

        <div class="mt-8 text-center">
          <h3 class="text-lg font-semibold leading-6 text-gray-900">
            {{ __('modals.delete.title') }}
          </h3>

          <div class="mt-2 text-gray-500">
            {{ __('modals.delete.text') }}
          </div>
        </div>
      </div>

      <div class="mt-8 text-center space-x-2">
        <button
          type="button"
          class="bg-controls hover:bg-controls/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap disabled:bg-controls/40"
          :disabled="loading"
          @click="cancel"
        >
          {{ __('modals.delete.cancel') }}
        </button>
        <button
          type="button"
          class="bg-danger hover:bg-danger/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap disabled:bg-danger/40"
          :disabled="loading"
          @click="deleteAccount"
        >
          {{ __('modals.delete.confirm') }}
          <span
            v-show="loading"
            class="fa fa-spinner animate-spin ml-1"
          />
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { ref } from 'vue';
import Modal from '../../../../Components/Modal.vue';

export default {
  components: {
    Modal,
  },

  props: {
    initialOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    return {
      isOpen: ref(props.initialOpen),
    };
  },

  data() {
    return {
      loading: false,
      cancelCallback: null,
      deleteCallback: null,
    };
  },

  methods: {
    open(callbacks) {
      this.isOpen = true;
      this.cancelCallback = callbacks.cancel;
      this.deleteCallback = callbacks.delete;
    },

    cancel() {
      this.cancelCallback();
      this.loading = false;
      this.isOpen = false;
    },

    deleteAccount() {
      this.loading = true;

      this.deleteCallback()
        .then(() => {
          this.loading = false;
          this.isOpen = false;
        });
    },
  },
};
</script>
