<template>
  <div class="font-bold text-3xl mb-4">
    {{ __('survey.location.title') }}
  </div>

  <div class="mb-6">
    <LocationSuggestionInput
      v-model="$store.state.SurveyForm.jobSearch.location"
      class="w-full"
      :load-initial-from-value="false"
      :placeholder="__('survey.location.input-placeholder')"
    />
  </div>

  <div class="flex items-center space-x-4">
    <button
      type="button"
      class="bg-controls hover:bg-controls-dark transition-colors text-lg text-white px-12 py-3 rounded disabled:bg-controls/40"
      :disabled="!canContinue"
      @click="next"
    >
      {{ __('survey.location.button') }}
    </button>

    <button
      type="button"
      class="text-controls hover:text-controls-dark hover:underline px-8 py-3"
      @click="next"
    >
      {{ __('survey.location.skip') }}
    </button>
  </div>
</template>

<script>
import LocationSuggestionInput from '../../../../Components/LocationSuggestionInput.vue';

export default {
  components: {
    LocationSuggestionInput,
  },

  emits: ['next'],

  data() {
    return {
      canContinue: false,
    };
  },

  watch: {
    '$store.state.SurveyForm.jobSearch.location': {
      deep: true,
      handler: function(location) {
        this.canContinue = 'placeId' in location && location.placeId && location.placeId.length > 0;
      },
    },
  },

  methods: {
    next() {
      this.$emit('next');
    },
  },
};
</script>
