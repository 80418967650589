<template>
  <slot
    name="button"
    :toggle="toggle"
  />

  <Teleport to="body">
    <div
      class="md:hidden absolute top-0 transition-all duration-200 h-screen overflow-y-hidden w-screen"
      :class="isOpen ? 'left-0' : '-left-full'"
    >
      <div class="flex w-full h-full">
        <div class="bg-secondary text-white h-full min-w-[16rem]">
          <div class="border-b border-gray-600 px-4 pt-2 pb-4">
            <div class="flex justify-end text-white text-4xl mb-6">
              <button
                type="button"
                @click="toggle"
              >
                <span class="fa fa-times" />
              </button>
            </div>

            <InertiaLink :href="route('frontend.index')">
              <img
                :src="asset('logo-white.svg')"
                :alt="$page.props.config.brandName"
              >
            </InertiaLink>
          </div>

          <div class="px-4 pt-4 space-y-4">
            <InertiaLink
              :href="route('frontend.index')"
              class="block"
              @click="toggle"
            >
              {{ __('footer.buttons.home') }}
            </InertiaLink>
            <InertiaLink
              :href="route('frontend.index')"
              class="block"
              @click="toggle"
            >
              {{ __('footer.buttons.find-jobs') }}
            </InertiaLink>
            <InertiaLink
              :href="route('frontend.post-a-job')"
              class="block"
              @click="toggle"
            >
              {{ __('footer.buttons.post-a-job') }}
            </InertiaLink>
            <InertiaLink
              :href="route('frontend.faq')"
              class="block"
              @click="toggle"
            >
              {{ __('footer.buttons.faq') }}
            </InertiaLink>
            <InertiaLink
              :href="route('frontend.contact.index')"
              class="block"
              @click="toggle"
            >
              {{ __('footer.buttons.get-in-touch') }}
            </InertiaLink>
            <InertiaLink
              :href="route('frontend.privacy-policy')"
              class="block"
              @click="toggle"
            >
              {{ __('footer.buttons.privacy-policy') }}
            </InertiaLink>
            <InertiaLink
              :href="route('frontend.terms-and-conditions')"
              class="block"
              @click="toggle"
            >
              {{ __('footer.buttons.terms-and-conditions') }}
            </InertiaLink>
          </div>
        </div>
        <div
          class="h-full w-full cursor-pointer"
          @click="toggle"
        />
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.body.classList.add('overflow-y-hidden');
      } else {
        document.body.classList.remove('overflow-y-hidden');
      }
    },
  },
};
</script>
