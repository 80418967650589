<template>
  <div class="text-xs mt-4 mb-4 md:mb-8">
    <InertiaLink :href="route('frontend.index')">
      {{ $page.props.config.brandName }}
    </InertiaLink>

    <template
      v-for="(breadcrumb, i) in breadcrumbComponents"
      :key="`breadcrumb-${i}`"
    >
      <span class="fa fa-chevron-right px-1" />
      <button
        type="button"
        class="hover:underline"
        @click="breadcrumbClick(breadcrumb.jobSearch)"
      >
        {{ breadcrumb.text }}
      </button>
    </template>
  </div>
</template>

<script>
import {upperFirst} from 'lodash';
import CountryUtils from '../../../../Mixins/CountryUtils.js';

export default {
  mixins: [CountryUtils],

  emits: ['search'],

  computed: {
    breadcrumbComponents() {
      const components = [];
      const jobSearch = this.$store.state.Index.jobSearch;

      if (jobSearch.keywords && jobSearch.keywords.length > 0) {
        components.push({
          text: upperFirst(jobSearch.keywords.join(', ')),
          jobSearch: {
            keywords: jobSearch.keywords,
            location: {
              country: jobSearch.location.country,
              radius: jobSearch.location.radius || 40,
            },
          },
        });
      }

      if (jobSearch.location) {
        if (jobSearch.location.country) {
          const translationKey = `breadcrumbs.country.${
            this.getCountry(jobSearch.location.country || '')
              .toLowerCase()
              .replace(' ', '-')
          }`;

          let translatedCountry = this.__(translationKey);
          if (translationKey === translatedCountry) {
            translatedCountry = jobSearch.location.country;
          }

          components.push({
            text: translatedCountry,
            jobSearch: {
              keywords: jobSearch.keywords,
              location: {
                country: jobSearch.location.country,
                radius: jobSearch.location.radius || 40,
              },
            },
          });
        }

        if (jobSearch.location.state) {
          components.push({
            text: this.getStateByCountry(jobSearch.location.country, jobSearch.location.state),
            jobSearch: {
              keywords: jobSearch.keywords,
              location: {
                state: jobSearch.location.state,
                country: jobSearch.location.country,
                radius: jobSearch.location.radius || 40,
              },
            },
          });
        }

        if (jobSearch.location.city) {
          components.push({
            text: jobSearch.location.city,
            jobSearch: {
              keywords: jobSearch.keywords,
              location: {
                city: jobSearch.location.city,
                state: jobSearch.location.state,
                country: jobSearch.location.country,
                radius: jobSearch.location.radius || 40,
              },
            },
          });
        }

        if (jobSearch.location.postalCode) {
          components.push({
            text: jobSearch.location.postalCode,
            jobSearch: {
              keywords: jobSearch.keywords,
              location: {
                postalCode: jobSearch.location.postalCode,
                city: jobSearch.location.city,
                state: jobSearch.location.state,
                country: jobSearch.location.country,
                radius: jobSearch.location.radius || 40,
              },
            },
          });
        }
      }

      return components;
    },
  },

  methods: {
    breadcrumbClick(jobSearch) {
      if (jobSearch) {
        this.$store.commit('Index/setJobSearch', jobSearch);
      }

      this.$emit('search');
    },
  },
};
</script>
