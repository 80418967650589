<template>
  <InertiaHead :title="__('company-details.title')" />
  <main class="content container md:px-12 mt-8 md:mt-18">
    <h1
      v-text="__('company-details.title')"
    />
    <p
      v-for="i in 4"
      :key="i"
      class="my-5"
    >
      <strong v-text="__(`company-details.companies[${i-1}].name`)" /><br>
      <span v-text="__(`company-details.companies[${i-1}].street`)" /><br>
      <span v-text="__(`company-details.companies[${i-1}].place`)" /><br>
      <span v-text="__(`company-details.companies[${i-1}].country`)" /><br>
      <span v-text="__(`company-details.companies[${i-1}].vat`)" /><br>
      <span v-text="__(`company-details.companies[${i-1}].coc`)" /><br>
      <span v-text="__(`company-details.companies[${i-1}].iban`)" /><br>
      <span v-text="__(`company-details.companies[${i-1}].bic`)" /><br>
    </p>
    <p v-text="__('company-details.phone')" />
    <p>
      <strong v-text="__('company-details.bank.title')" /><br>
      <span v-text="__('company-details.bank.name')" /><br>
      <span v-text="__('company-details.bank.address')" /><br>
      <span v-text="__('company-details.bank.city')" />
    </p>
  </main>
</template>
