<template>
  <InertiaHead :title="title" />

  <main class="container md:px-12 mt-12 md:mt-18">
    <div class="text-center">
      <template v-if="status >= 400 && status < 500">
        <Error404Icon />
      </template>

      <h1 class="font-bold text-4xl mb-4">
        {{ title }}
      </h1>

      <h2 class="text-lg mb-8">
        {{ __('oops.description') }}
      </h2>

      <InertiaLink
        :href="route('frontend.index')"
        class="block mx-auto w-full sm:w-40 bg-primary hover:bg-primary-dark hover:no-underline text-white text-center transition-colors px-4 py-2 rounded"
      >
        {{ __('oops.button') }}
      </InertiaLink>
    </div>
  </main>
</template>

<script>
import Error404Icon from '../../Icons/Errors/404.vue';

export default {
  components: {
    Error404Icon,
  },

  props: {
    status: {
      required: true,
      type: Number,
    },
  },

  computed: {
    title() {
      if (this.status >= 400 && this.status < 500) {
        return this.__('oops.title-404');
      }

      return this.__('oops.title');
    },
  },
};
</script>
