<template>
  <template v-if="show">
    <div class="py-4 px-6 mb-8 bg-yellow-400/40 text-yellow-700 rounded-md">
      {{ __('profile.opt-in-message') }}<br>

      <template v-if="!loading">
        {{ __('profile.opt-in-resend-text.text') }}
        <button
          type="button"
          class="font-semibold hover:underline"
          @click="resendOptInConfirmMail"
        >
          {{ __('profile.opt-in-resend-text.link') }}
        </button>
      </template>
      <template v-else>
        <span class="fa fa-spinner animate-spin mr-1" />
        {{ __('profile.opt-in-resend-text.requesting') }}
      </template>
    </div>

    <OptInResendModal ref="opt-in-resend" />
    <OptInTimeoutErrorModal ref="opt-in-timeout-error" />
    <ErrorModal ref="opt-in-error" />
  </template>
</template>

<script>
import axios from 'axios';
import OptInResendModal from './OptInResendModal.vue';
import OptInTimeoutErrorModal from './OptInTimeoutErrorModal.vue';
import ErrorModal from '../../Pages/Auth/Components/ErrorModal.vue';

export default {
  components: {
    OptInResendModal,
    OptInTimeoutErrorModal,
    ErrorModal,
  },

  data() {
    return {
      loading: false,
      hide: false,
    };
  },

  computed: {
    show () {
      return this.$page.props.loggedIn
        && this.$page.props.jobSeeker
        && !this.$page.props.jobSeeker.optIn
        && !this.hide;
    },
  },

  methods: {
    resendOptInConfirmMail () {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(this.route('frontend.api.optin.resend-reminder'), {
        job_seeker_id: this.$page.props.jobSeeker.id,
      })
        .then(() => {
          this.$refs['opt-in-resend'].open();
        })
        .catch(({response}) => {
          if (response?.data?.error === 'timeout') {
            this.$refs['opt-in-timeout-error'].open(response.data['retry-after']);
          } else if (response?.data?.error === 'already-opt-in') {
            this.hide = true;
          } else {
            this.$refs['opt-in-error'].open();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
