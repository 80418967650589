export default function install(app, { googleAnalyticsId }) {
  if (!window.ga || !googleAnalyticsId) {
    return;
  }

  window.ga('create', googleAnalyticsId, 'auto');

  app.config.globalProperties.$inertia.on('navigate', (event) => {
    setTimeout(() => {
      const title = document.head.querySelector('title');

      window.ga('set', {
        page: event.detail.page.url,
        title: title?.text || '',
      });

      window.ga('send', 'pageview');
    }, 10);
  });
}
