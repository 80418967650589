export default function install(app) {
  window.dataLayer = window.dataLayer || [];

  app.config.globalProperties.$inertia.on('navigate', (event) => {
    const query = event.detail.page.url.split('?', 2);

    window.dataLayer.push({
      url_params: query.length === 2 ? query[1] : '',
      language: app.config.globalProperties.$page.props.language,
      cloudflare: {
        country: app.config.globalProperties.$page.props.requestData.country,
      },
      job_seeker: {
        scheduler_id: app.config.globalProperties.$page.props.jobSeeker?.scheduler_id,
      },
    });
  });
}
