export default function install(app) {
  app.config.globalProperties.$tracker = {
    jobClick(job) {
      window.dataLayer.push({event: 'WEBSITE_CLICK', job: {source: job.source}});
    },

    signUp() {
      this.login();
      window.dataLayer.push({event: 'jobAlertSignUp'});
    },

    login(jobSeeker) {
      if (window.dataLayer && jobSeeker?.id) {
        window.dataLayer.push({jobSeeker: {id: jobSeeker.id}});
      }
    },

    logout() {
      window.dataLayer.push({jobSeeker: {id: null}});
    },

    search(query, city) {
      window.dataLayer.push({event: 'searchComplete', page: 1, query, city});
    },

    pageChange(page, query, city) {
      window.dataLayer.push({event: 'searchChangePage', page, query, city});
    },
  };
}
