<template>
  <div
    v-if="show"
    class="container pt-4"
  >
    <div class="px-6 py-4 bg-yellow-400/40 text-yellow-700 rounded-md">
      {{ __('impersonate.title') }}<br>
      <InertiaLink
        :href="route('frontend.auth.logout')"
        method="post"
        as="button"
        type="button"
        class="font-semibold hover:underline"
      >
        {{ __('impersonate.logout') }}
      </InertiaLink>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    show () {
      return this.$page.props.impersonate;
    },
  },
};
</script>
