<template>
  <div class="grid grid-cols-12 py-8 border-b border-gray-200">
    <div class="hidden md:block col-span-1">
      <button
        type="button"
        class="bg-success hover:bg-success/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap"
        @click="$emit('add', index)"
      >
        <span class="fa fa-plus" />
      </button>
    </div>

    <div class="col-span-11 md:col-span-10 space-y-4 mr-6 sm:mr-0 md:mr-2">
      <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
        <label
          :for="`what[${index}]`"
          class="md:col-span-4 md:text-right"
        >
          {{ __('profile.job-alerts.add.keywords-input.label') }}
        </label>
        <MultiKeywordSuggestionInput
          :id="`what[${index}]`"
          v-model="$store.state.ProfileJobAlertSettings.jobSearches[index].keywords"
          :placeholder="__('profile.job-alerts.add.keywords-input.placeholder')"
          class="w-full md:col-span-8"
        />
      </div>

      <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
        <label
          :for="`where[${index}]`"
          class="md:col-span-4 md:text-right"
        >
          {{ __('profile.job-alerts.add.location-input.label') }}
        </label>
        <LocationSuggestionInput
          :id="`where[${index}]`"
          v-model="$store.state.ProfileJobAlertSettings.jobSearches[index].location"
          class="w-full md:col-span-8"
          :placeholder="__('profile.job-alerts.add.location-input.placeholder')"
        />
      </div>

      <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
        <label
          :for="`max_travel_distance[${index}]`"
          class="col-span-12 md:col-span-4 md:text-right"
        >
          {{ __('profile.job-alerts.add.max-travel-distance-input.label') }}
        </label>
        <select
          :id="`max_travel_distance[${index}]`"
          v-model="$store.state.ProfileJobAlertSettings.jobSearches[index].location.radius"
          class="w-full md:col-span-8"
        >
          <template
            v-for="distance in distances"
            :key="`distance-${distance}`"
          >
            <option :value="distance">
              {{ __('definitions.distance', { distance }) }}
            </option>
          </template>
        </select>
      </div>

      <div class="md:grid md:grid-cols-12 md:gap-x-6">
        <label class="md:col-span-4 md:text-right">
          {{ __('profile.job-alerts.add.age-input.label') }}
        </label>
        <div class="flex flex-col col-span-8">
          <template
            v-for="age in ages"
            :key="`age-${age}`"
          >
            <label class="flex items-center">
              <input
                v-model="$store.state.ProfileJobAlertSettings.jobSearches[index].jobAge"
                :name="`job_age[${index}]`"
                type="radio"
                :value="age"
              >
              {{ __('job-filter.published-at', { search_date: age }) }}
            </label>
          </template>
        </div>
      </div>

      <div class="md:grid md:grid-cols-12 md:items-center md:gap-x-6">
        <label
          :for="`emails_interval[${index}]`"
          class="md:col-span-4 md:text-right"
        >
          {{ __('profile.job-alerts.add.max-job-alerts-settings.label') }}
        </label>
        <select
          :id="`emails_interval[${index}]`"
          v-model="$store.state.ProfileJobAlertSettings.jobSearches[index].maxJobAlertsSetting"
          class="w-full md:col-span-8"
        >
          <template
            v-for="(maxJobAlert, i) in maxJobAlerts"
            :key="`max-job-alert-${i}`"
          >
            <option
              :value="maxJobAlert.value"
            >
              {{ maxJobAlert.name }}
            </option>
          </template>
        </select>
      </div>

      <div class="grid grid-cols-12 gap-x-6">
        <label class="col-span-12 md:col-span-4 md:text-right">
          {{ __('profile.job-alerts.add.job-type-input.label') }}
        </label>
        <div class="flex flex-col col-span-8">
          <label class="flex items-center">
            <input
              v-model="$store.state.ProfileJobAlertSettings.jobSearches[index].jobTypes"
              :name="`job_type[${index}]`"
              type="radio"
              :value="['FULL_TIME']"
            >
            {{ __('definitions.job-types.full_time') }}
          </label>
          <label class="flex items-center">
            <input
              v-model="$store.state.ProfileJobAlertSettings.jobSearches[index].jobTypes"
              :name="`job_type[${index}]`"
              type="radio"
              :value="['PART_TIME']"
            >
            {{ __('definitions.job-types.part_time') }}
          </label>
          <label class="flex items-center">
            <input
              v-model="$store.state.ProfileJobAlertSettings.jobSearches[index].jobTypes"
              :name="`job_type[${index}]`"
              type="radio"
              :value="[]"
            >
            {{ __('definitions.job-types.all') }}
          </label>
        </div>
      </div>
    </div>

    <div class="col-span-1 text-right">
      <button
        v-show="$store.state.ProfileJobAlertSettings.jobSearches.length > 1"
        type="button"
        class="border border-danger hover:bg-danger text-danger hover:text-white transition-colors text-sm md:text-base px-2 py-1 md:px-4 md:py-2 rounded whitespace-nowrap"
        @click="$emit('remove', index)"
      >
        <span class="fa fa-times" />
      </button>
    </div>
  </div>
</template>

<script>
import MultiKeywordSuggestionInput from '../../../../Components/MultiKeywordSuggestionInput.vue';
import LocationSuggestionInput from '../../../../Components/LocationSuggestionInput.vue';
import FilterMixin from '../../../../Pages/Index/Components/Serp/FilterMixin';

export default {
  components: {
    MultiKeywordSuggestionInput,
    LocationSuggestionInput,
  },

  mixins: [FilterMixin],

  props: {
    index: {
      required: true,
      type: Number,
    },
  },

  emits: ['add', 'remove', 'loading'],
};
</script>
