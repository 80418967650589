<template>
  <div class="font-bold text-3xl mb-4 md:mb-6">
    {{ __('survey.enable-job-alerts.title') }}
  </div>
  <div class="mb-4 text-gray-400 text-sm md:text-base">
    <i18n-t
      keypath="survey.enable-job-alerts.subtitle"
      scope="global"
    >
      <template #terms>
        <InertiaLink
          :href="route('frontend.terms-and-conditions')"
          class="underline hover:no-underline"
        >
          {{ __('survey.enable-job-alerts.subtitle-link') }}
        </InertiaLink>
      </template>
    </i18n-t>
  </div>

  <div class="mb-6">
    <div class="space-y-2">
      <button
        class="relative w-full flex flex-col justify-center bg-primary/5 hover:bg-controls/20 rounded text-primary text-left px-6 py-3 rounded border border-primary-dark transition-colors cursor-pointer"
        @click="$emit('next', 5)"
      >
        <div class="font-medium">
          {{ __('survey.enable-job-alerts.yes') }}
        </div>
      </button>

      <button
        class="relative w-full flex flex-col justify-center bg-primary/5 hover:bg-controls/20 rounded text-primary text-left px-6 py-3 rounded border border-primary-dark transition-colors cursor-pointer"
        @click="$emit('next', 6)"
      >
        <div class="font-medium">
          {{ __('survey.enable-job-alerts.no') }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['next'],
};
</script>
