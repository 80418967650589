<template>
  <div class="hidden lg:block w-1/4 space-y-6">
    <div class="text-lg font-bold">
      {{ __('job-filter.title') }}
    </div>

    <div>
      <div class="font-bold">
        {{ __('job-filter.job-types.title') }}
      </div>
      <div class="flex flex-col space-y-1 text-sm">
        <label class="flex items-center">
          <input
            v-model="$store.state.Index.jobSearch.jobTypes"
            name="job_type"
            type="radio"
            :value="['FULL_TIME']"
          >
          {{ __('definitions.job-types.full_time') }}
        </label>
        <label class="flex items-center">
          <input
            v-model="$store.state.Index.jobSearch.jobTypes"
            name="job_type"
            type="radio"
            :value="['PART_TIME']"
          >
          {{ __('definitions.job-types.part_time') }}
        </label>
        <label class="flex items-center">
          <input
            v-model="$store.state.Index.jobSearch.jobTypes"
            name="job_type"
            type="radio"
            :value="[]"
          >
          {{ __('definitions.job-types.all') }}
        </label>
      </div>
    </div>

    <div>
      <div class="font-bold">
        {{ __('job-filter.filter-by.travel-distance.distance-input.label') }}
      </div>
      <div class="flex flex-col space-y-1">
        <select
          v-model="$store.state.Index.jobSearch.location.radius"
          class="text-sm rounded focus:ring-primary focus:border-primary"
        >
          <template
            v-for="distance in distances"
            :key="`distance-${distance}`"
          >
            <option :value="distance">
              {{ __('definitions.distance', { distance }) }}
            </option>
          </template>
        </select>
      </div>
    </div>

    <div>
      <div class="font-bold">
        {{ __('job-filter.job-age') }}
      </div>
      <div class="flex flex-col space-y-1 text-sm">
        <template
          v-for="age in ages"
          :key="`age-${age}`"
        >
          <label class="flex items-center">
            <input
              v-model="$store.state.Index.jobSearch.jobAge"
              name="job_age"
              type="radio"
              :value="age"
            >
            {{ __('job-filter.published-at', { search_date: age }) }}
          </label>
        </template>
      </div>
    </div>

    <button
      type="button"
      class="border border-controls hover:bg-controls text-controls hover:text-white transition-colors px-4 py-2 rounded w-full"
      @click="search"
    >
      {{ __('job-filter.apply-button') }}
    </button>
  </div>
</template>

<script>
import FilterMixin from './FilterMixin';

export default {
  mixins: [FilterMixin],
};
</script>
