<template>
  <main class="container">
    <div class="md:w-2/5 mx-auto mt-8 md:mt-32">
      <LoginIcon class="mx-auto" />

      <div class="text-center">
        <h1 class="font-bold text-3xl mb-2">
          {{ __('login.title') }}
        </h1>

        <div class="mb-4">
          {{ __('login.get-otp-description') }}
        </div>

        <form @submit.prevent="requestOneTimePassword">
          <input
            ref="email-input"
            v-model="email"
            type="email"
            autocomplete="email"
            class="w-full sm:w-80 mb-2"
            :class="errors.email ? 'border-danger' : ''"
            :placeholder="__('login.email-input-placeholder')"
          >
          <small
            v-if="errors.email"
            class="block text-danger mb-2"
            v-text="errors.email"
          />

          <button
            type="submit"
            class="w-full sm:w-80 bg-controls hover:bg-controls-dark text-white transition-colors px-4 py-2 rounded disabled:bg-controls/40"
            :disabled="loading"
          >
            {{ __('login.get-otp-button') }}
            <span
              v-show="loading"
              class="fa fa-spinner animate-spin ml-3"
            />
          </button>
        </form>

        <template v-if="hasRedactedInformation">
          <div class="mt-12 text-sm text-gray-400">
            <template v-if="redacted.email">
              {{ __('login.recognized.email-text', { redactedEmail: redacted.email }) }}
            </template>
            <template v-else-if="redacted.mobilePhoneNumber">
              {{ __('login.recognized.mobilePhoneNumber-text', { redactedMobilePhoneNumber: redacted.mobilePhoneNumber }) }}
            </template>

            <form @submit.prevent="logout">
              <button
                type="submit"
                class="block mx-auto text-controls hover:text-controls-dark"
              >
                {{ __('login.recognized.logout') }}
              </button>
            </form>
          </div>
        </template>
      </div>
    </div>
  </main>

  <ErrorModal ref="error-modal" />
</template>

<script>
import LoginIcon from '../../Icons/Auth/Login.vue';
import ErrorModal from './Components/ErrorModal.vue';

export default {
  components: {
    LoginIcon,
    ErrorModal,
  },

  props: {
    redacted: {
      required: false,
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      email: '',
      errors: {},
    };
  },

  computed: {
    hasRedactedInformation() {
      return this.redacted && (this.redacted.email || this.redacted.mobilePhoneNumber);
    },
  },

  mounted() {
    this.$refs['email-input'].focus();
  },

  methods: {
    requestOneTimePassword() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.errors = {};

      this.$inertia.post(this.route('frontend.auth.request-otp'), {
        email: this.email,
      }, {
        onError: (errors) => {
          this.errors = errors;
          this.$refs['error-modal'].open();
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },

    logout() {
      this.$inertia.post(this.route('frontend.auth.redacted-logout'));
    },
  },
};
</script>
