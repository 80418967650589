<template>
  <template v-if="show">
    <div class="py-4 px-6 mb-8 bg-red-400/40 text-red-700 rounded-md">
      <i18n-t
        keypath="profile.unsubscribed-message"
        scope="global"
      >
        <template #link>
          <InertiaLink
            :href="route('frontend.profile.notifications.index')"
            class="underline hover:no-underline"
          >
            {{ __('profile.unsubscribed-link') }}
          </InertiaLink>
        </template>
      </i18n-t>
    </div>
  </template>
</template>

<script>
export default {
  computed: {
    show () {
      return this.$page.props.loggedIn
        && this.$page.props.jobSeeker
        && this.$page.props.jobSeeker.unsubscribed;
    },
  },
};
</script>
