<template>
  <InertiaHead :title="__('unsubscribe.title')" />

  <main class="container">
    <div class="md:w-2/5 mx-auto mt-8 md:mt-32">
      <div class="text-center">
        <span class="fa fa-check text-success text-7xl mb-6" />

        <h1 class="font-bold text-3xl mb-2">
          {{ __('unsubscribe.title') }}
        </h1>

        <div class="mb-12">
          {{ __('unsubscribe.auto-unsubscribed') }}
        </div>

        <span
          v-if="loading"
          class="fa fa-spinner animate-spin ml-3"
        />
        <div
          v-else
          class="text-center"
        >
          <form @submit.prevent="$refs.recaptchaForm.formSubmit()">
            <button
              type="submit"
              class="text-controls hover:text-controls-dark hover:underline text-sm"
              @click="showRecaptcha = true"
            >
              {{ __('unsubscribe.undo') }}
            </button>

            <HiddenRecaptcha
              v-show="showRecaptcha"
              ref="recaptchaForm"
              @success="resubscribe"
            />
          </form>
        </div>
      </div>
    </div>
  </main>

  <ErrorModal ref="error-modal" />
</template>

<script>
import ErrorModal from '../Auth/Components/ErrorModal.vue';
import HiddenRecaptcha from '../../Components/HiddenRecaptcha.vue';

export default {
  components: {
    HiddenRecaptcha,
    ErrorModal,
  },

  props: {
    jobSeekerId: {
      required: true,
      type: String,
    },

    jobSeekerHash: {
      required: true,
      type: String,
    },
  },

  data () {
    return {
      loading: false,
      showRecaptcha: false,
    };
  },

  methods: {
    resubscribe(recaptcha) {
      this.loading = true;

      this.$inertia.post(this.route('frontend.unsubscribe.resubscribe'), {
        jobSeekerId: this.jobSeekerId,
        hash: this.jobSeekerHash,
        recaptcha,
      }, {
        onError() {
          this.loading = false;
          this.$refs['error-modal'].open();
        },
      });
    },
  },
};
</script>
