<template>
  <div class="font-bold text-3xl mb-6">
    {{ __('survey.job-type.title') }}
  </div>

  <div class="mb-6">
    <div class="space-y-2">
      <template
        v-for="(label, value) in jobTypes"
        :key="`job-type-${value}`"
      >
        <div
          class="relative flex flex-col justify-center bg-primary/5 hover:bg-primary/20 rounded text-primary px-6 py-3 rounded border border-primary-dark transition-colors cursor-pointer"
          :class="{ 'bg-primary/20 shadow-[0_0_0_1px_rgb(var(--color-primary-dark))]': false }"
          @click="selectJobType(value)"
        >
          <span
            v-show="false"
            class="fa fa-check text-sm absolute right-6"
          />
          <div class="font-medium">
            {{ label }}
          </div>
        </div>
      </template>
    </div>
  </div>

  <div class="flex items-center space-x-4">
    <button
      type="button"
      class="bg-controls hover:bg-controls-dark transition-colors text-lg text-white px-12 py-3 rounded disabled:bg-controls/40"
      @click="$emit('next')"
    >
      {{ __('survey.job-type.button') }}
    </button>

    <button
      type="button"
      class="text-controls hover:text-controls-dark hover:underline px-8 py-3"
      @click="$emit('next')"
    >
      {{ __('survey.job-type.skip') }}
    </button>
  </div>
</template>

<script>
export default {
  emits: ['next'],

  data() {
    return {
      jobTypes: {
        'FULL_TIME': this.__('survey.job-type.full-time'),
        'PART_TIME': this.__('survey.job-type.part-time'),
        'ALL': this.__('survey.job-type.all'),
      },
    };
  },

  methods: {
    selectJobType(jobType) {
      if (jobType === 'ALL') {
        this.$store.state.SurveyForm.jobSearch.jobTypes = [];
      } else {
        this.$store.state.SurveyForm.jobSearch.jobTypes = [jobType];
      }

      this.$emit('next');
    },
  },
};
</script>
