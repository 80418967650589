<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
      <path d="M18 14v5 0c0 1.10457-.895431 2-2 2h-11l-8.74228e-08-3.55271e-15c-1.10457-4.82823e-08-2-.895431-2-2 0 0 0 0 0 0v-14l2.30926e-14 3.01992e-07c-1.66785e-07-1.10457.89543-2 2-2h11l-8.74228e-08 1.77636e-15c1.10457-4.82823e-08 2 .89543 2 2v3" />
      <path d="M13 13.414v2.586h2.586l1.02845e-08-2.19735e-12c.265195-5.66401e-05.519507-.105451.707-.293l4.086-4.086 8.14151e-08-8.14673e-08c.828052-.828583.828052-2.17142-1.6283e-07-3v0l3.18521e-08 3.18317e-08c-.828582-.828052-2.17142-.828052-3-6.36635e-08l-4.086 4.086 -4.20098e-08 4.19971e-08c-.187549.187493-.292943.441805-.293.707Z" />
      <line x1="7" x2="10" y1="12" y2="12" />
      <line x1="7" x2="10" y1="16" y2="16" />
      <line x1="7" x2="14" y1="8" y2="8" />
    </g>
    <path fill="none" d="M0 0h24v24h-24Z" />
  </svg>
</template>
