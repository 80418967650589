<template>
  <main class="container">
    <div class="md:w-2/5 mx-auto mt-8 md:mt-32">
      <LoginIcon class="mx-auto" />

      <div class="text-center">
        <h1 class="font-bold text-3xl mb-2">
          {{ __('login.title') }}
        </h1>

        <div class="mb-4">
          {{ __('login.confirm-otp-description') }}
        </div>

        <form @submit.prevent="confirmOneTimePassword">
          <input
            ref="otp-input"
            v-model="otp"
            type="text"
            autocomplete="one-time-code"
            class="w-full sm:w-80 mb-2"
            :class="errors.otp || errors['one-time-password'] ? 'border-danger' : ''"
            :placeholder="__('login.otp-input-placeholder')"
          >
          <small
            v-if="errors.otp || errors['one-time-password']"
            class="block text-danger mb-2"
            v-text="errors.otp || errors['one-time-password']"
          />

          <button
            type="submit"
            class="w-full sm:w-80 bg-controls hover:bg-controls-dark text-white transition-colors px-4 py-2 rounded disabled:bg-controls/40"
            :disabled="loading"
          >
            {{ __('login.confirm-otp-button') }}
            <span
              v-show="loading"
              class="fa fa-spinner animate-spin ml-3"
            />
          </button>
        </form>
      </div>
    </div>
  </main>

  <ErrorModal ref="error-modal" />
</template>

<script>
import LoginIcon from '../../Icons/Auth/Login.vue';
import ErrorModal from './Components/ErrorModal.vue';

export default {
  components: {
    LoginIcon,
    ErrorModal,
  },

  props: {
    email: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      otp: '',
      errors: {},
    };
  },

  mounted() {
    this.$refs['otp-input'].focus();
  },

  methods: {
    confirmOneTimePassword() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.errors = {};

      this.$inertia.post(this.route('frontend.auth.validate-otp'), {
        email: this.email,
        otp: this.otp,
      }, {
        onError: (errors) => {
          this.errors = errors;
          this.$refs['error-modal'].open();
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
};
</script>
