<template>
  <div class="font-bold text-3xl mb-6">
    {{ __('survey.keywords.title') }}
  </div>
  <div class="mb-4">
    {{ __('survey.keywords.subtitle') }}
  </div>

  <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 mb-2">
    <template
      v-for="block in keywordBlocks"
      :key="`keyword-block-${block.icon}`"
    >
      <div
        class="relative flex flex-col justify-center bg-primary/5 hover:bg-primary/20 rounded text-primary text-center p-2 rounded border border-primary-dark transition-colors min-h-[135px] cursor-pointer"
        :class="{ 'bg-primary/20 shadow-[0_0_0_1px_rgb(var(--color-primary-dark))]': $store.state.SurveyForm.selectedKeywords.includes(block.label) }"
        @click="toggleKeywordBlock(block.label)"
      >
        <span
          v-show="$store.state.SurveyForm.selectedKeywords.includes(block.label)"
          class="fa fa-check text-sm absolute top-2 right-2"
        />
        <div class="w-10 mx-auto">
          <Component
            :is="`${block.icon}-icon`"
            height="40"
            width="40"
          />
        </div>

        <div class="mt-4">
          {{ block.label }}
        </div>
      </div>
    </template>
  </div>

  <div
    v-show="showKeywordSearch"
    class="mb-6"
  >
    <MultiKeywordSuggestionInput
      v-model="$store.state.SurveyForm.customKeywords"
      class="w-full"
      :placeholder="__('survey.keywords.input-placeholder')"
    />
  </div>

  <div class="flex items-center space-x-4">
    <button
      type="button"
      class="bg-controls hover:bg-controls-dark transition-colors text-lg text-white px-12 py-3 rounded disabled:bg-controls/40"
      :disabled="!canContinue"
      @click="next"
    >
      {{ __('survey.keywords.button') }}
    </button>

    <button
      type="button"
      class="text-controls hover:text-controls-dark hover:underline px-8 py-3"
      @click="$emit('next')"
    >
      {{ __('survey.keywords.skip') }}
    </button>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import AdminIcon from '../../../../Icons/Tags/Admin.vue';
import CustomerServiceIcon from '../../../../Icons/Tags/CustomerService.vue';
import DeliveryDriverIcon from '../../../../Icons/Tags/DeliveryDriver.vue';
import EntryLevelIcon from '../../../../Icons/Tags/EntryLevel.vue';
import LocationActiveIcon from '../../../../Icons/Tags/LocationActive.vue';
import NurseIcon from '../../../../Icons/Tags/Nurse.vue';
import RestaurantIcon from '../../../../Icons/Tags/Restaurant.vue';
import RetailAssociateIcon from '../../../../Icons/Tags/RetailAssociate.vue';
import SalesIcon from '../../../../Icons/Tags/Sales.vue';
import TruckDriverIcon from '../../../../Icons/Tags/TruckDriver.vue';
import WarehouseWorkerIcon from '../../../../Icons/Tags/WarehouseWorker.vue';
import WorkFromHomeIcon from '../../../../Icons/Tags/WorkFromHome.vue';
import MultiKeywordSuggestionInput from '../../../../Components/MultiKeywordSuggestionInput.vue';

export default {
  components: {
    AdminIcon, CustomerServiceIcon, DeliveryDriverIcon, EntryLevelIcon, LocationActiveIcon, NurseIcon, RestaurantIcon, RetailAssociateIcon, SalesIcon, TruckDriverIcon, WarehouseWorkerIcon, WorkFromHomeIcon,
    MultiKeywordSuggestionInput,
  },

  emits: ['next', 'skip'],

  data() {
    return {
      canContinue: false,
      keywordBlocks: [
        {label: this.__('survey.keywords.blocks.admin'), icon: 'admin'},
        {label: this.__('survey.keywords.blocks.customer-service'), icon: 'customer-service'},
        {label: this.__('survey.keywords.blocks.delivery-driver'), icon: 'delivery-driver'},
        {label: this.__('survey.keywords.blocks.entry-level'), icon: 'entry-level'},
        {label: this.__('survey.keywords.blocks.nurse'), icon: 'nurse'},
        {label: this.__('survey.keywords.blocks.restaurant'), icon: 'restaurant'},
        {label: this.__('survey.keywords.blocks.retail-associate'), icon: 'retail-associate'},
        {label: this.__('survey.keywords.blocks.sales'), icon: 'sales'},
        {label: this.__('survey.keywords.blocks.truck-driver'), icon: 'truck-driver'},
        {label: this.__('survey.keywords.blocks.warehouse-worker'), icon: 'warehouse-worker'},
        {label: this.__('survey.keywords.blocks.work-from-home'), icon: 'work-from-home'},
        {label: this.__('survey.keywords.blocks.other'), icon: 'location-active'},
      ],
    };
  },

  computed: {
    showKeywordSearch() {
      return this.$store.state.SurveyForm.selectedKeywords.includes('Other');
    },
  },

  watch: {
    '$store.state.SurveyForm.selectedKeywords': {
      deep: true,
      handler: function(selectedKeywords) {
        this.canContinueCheck(selectedKeywords);
      },
    },

    '$store.state.SurveyForm.customKeywords': {
      deep: true,
      handler: function() {
        this.canContinueCheck(this.$store.state.SurveyForm.selectedKeywords);
      },
    },
  },

  methods: {
    toggleKeywordBlock(block) {
      if (this.$store.state.SurveyForm.selectedKeywords.includes(block)) {
        this.$store.commit('SurveyForm/removeSelectedKeyword', block);
      } else {
        this.$store.commit('SurveyForm/addSelectedKeyword', block);
      }
    },

    canContinueCheck(selectedKeywords) {
      if (selectedKeywords.length === 1 && selectedKeywords[0] === 'Other') {
        this.canContinue = this.$store.state.SurveyForm.customKeywords.length > 0;
        return;
      }

      this.canContinue = selectedKeywords.length > 0;
    },

    next() {
      const selectedKeywords = cloneDeep(this.$store.state.SurveyForm.selectedKeywords);
      const otherKeywordIndex = selectedKeywords.indexOf('Other');
      if (otherKeywordIndex !== -1) {
        selectedKeywords.splice(otherKeywordIndex, 1);
      }

      this.$store.state.SurveyForm.jobSearch.keywords = selectedKeywords.concat(this.$store.state.SurveyForm.customKeywords);

      this.$emit('next');
    },
  },
};
</script>
