<template>
  <InertiaHead :title="__('faq.title')" />
  <main class="content container md:px-12 mt-8 md:mt-18">
    <h1>
      {{ __('faq.title') }}
    </h1>

    <hr class="my-4">

    <h2>
      {{ __('faq.topics.how-to-apply.title') }}
    </h2>

    <p>
      {{ __('faq.topics.how-to-apply.sections.0') }}
      <img
        src="/assets/faq/job.png"
        class="img-fluid"
        width="605"
        height="361"
        alt=""
      >
    </p>
    <p>
      {{ __('faq.topics.how-to-apply.sections.1') }}
      <img
        src="/assets/faq/selected-job.png"
        class="img-fluid"
        width="581"
        height="282"
        alt=""
      >
    </p>
    <p>
      {{ __('faq.topics.how-to-apply.sections.2') }}
      <img
        src="/assets/faq/external-job.png"
        class="img-fluid"
        width="586"
        height="318"
        alt=""
      >
    </p>
    <p>
      {{ __('faq.topics.how-to-apply.sections.3') }}
    </p>

    <hr class="my-4">

    <h2>
      {{ __('faq.topics.how-do-i-unsubscribe.title') }}
    </h2>

    <p>
      {{ __('faq.topics.how-do-i-unsubscribe.sections.0') }}
      <img
        src="/assets/faq/unsubscribe.png"
        class="img-fluid"
        width="471"
        height="259"
        alt=""
      >
    </p>
    <p>
      {{ __('faq.topics.how-do-i-unsubscribe.sections.1') }}
      <img
        src="/assets/faq/profile-unsubscribe.png"
        class="img-fluid"
        width="478"
        height="298"
        alt=""
      >
    </p>
    <p>
      {{ __('faq.topics.how-do-i-unsubscribe.sections.2') }}
      <img
        src="/assets/faq/modal-unsubscribe.png"
        class="img-fluid"
        width="457"
        height="173"
        alt=""
      >
    </p>

    <hr class="my-4">

    <i18n-t
      keypath="faq.topics.footer.text"
      scope="global"
    >
      <template #link>
        <InertiaLink
          :href="route('frontend.contact.index')"
          class="underline"
          target="_blank"
        >
          {{ __('faq.topics.footer.link.text') }}
        </InertiaLink>
      </template>
    </i18n-t>
  </main>
</template>
