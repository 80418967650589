<template>
  <div class="font-bold text-3xl mb-4">
    {{ __('survey.email.title') }}
  </div>

  <div class="mb-6">
    <input
      v-model="$store.state.SurveyForm.jobSeeker.email"
      type="email"
      class="w-full"
      :class="errors.email ? 'border-danger' : ''"
      :placeholder="__('survey.email.input-placeholder')"
    >
    <small
      v-if="errors.email"
      class="text-danger"
      v-text="errors.email[0]"
    />
  </div>

  <div class="flex items-center space-x-4">
    <button
      type="button"
      class="bg-controls hover:bg-controls-dark transition-colors text-lg text-white px-12 py-3 rounded disabled:bg-controls/40"
      :disabled="!canContinue || loading"
      @click="storeJobSeeker"
    >
      {{ __('survey.email.button') }}
      <span v-show="loading">
        <span class="fa fa-spinner animate-spin ml-3" />
      </span>
    </button>

    <button
      type="button"
      class="text-controls hover:text-controls-dark hover:underline px-8 py-3"
      @click="$emit('next')"
    >
      {{ __('survey.email.skip') }}
    </button>
  </div>

  <ErrorModal ref="error-modal" />
</template>

<script>
import axios from 'axios';
import ErrorModal from '../../../../Pages/Auth/Components/ErrorModal.vue';

export default {
  components: {ErrorModal},
  emits: ['next'],

  data() {
    return {
      canContinue: false,
      loading: false,
      errors: {},
    };
  },

  watch: {
    '$store.state.SurveyForm.jobSeeker.email': function(email) {
      this.canContinue = email.length >= 6 && email.includes('@');
    },
  },

  methods: {
    storeJobSeeker() {
      this.errors = {};
      this.loading = true;

      axios.post(this.route('frontend.api.job-seeker.store'), {
        jobSeeker: this.$store.state.SurveyForm.jobSeeker,
        jobSearch: this.$store.state.SurveyForm.jobSearch,
      })
        .then(() => {
          this.loading = false;

          this.$tracker.signUp();

          this.$emit('next');
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.loading = false;

          this.$refs['error-modal'].open();
        });
    },
  },
};
</script>
