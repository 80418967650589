<template>
  <div>
    <main class="container mt-12 md:mt-16">
      <div class="text-center">
        <img
          :src="asset('logo.svg')"
          :alt="$page.props.config.brandName"
          class="h-10 mx-auto mt-8"
        >

        <h2 class="font-bold text-2xl md:text-3xl my-8">
          Please verify you are human 🤖
        </h2>

        <form>
          <HiddenRecaptcha
            ref="recaptchaForm"
            @success="onSuccess"
          />
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import HiddenRecaptcha from '../../Components/HiddenRecaptcha.vue';
import Clean from '../../Layouts/Clean.vue';

export default {
  components: {
    HiddenRecaptcha,
  },

  layout: Clean,

  methods: {
    onSuccess(recaptcha) {
      this.$inertia.post(this.route('frontend.recaptcha.completed'), {
        recaptcha,
      });
    },
  },
};
</script>
