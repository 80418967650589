<template>
  <div class="md:hidden relative">
    <slot
      name="button"
      :open="open"
    />

    <div
      class="absolute top-0 transition-all duration-200 min-w-[18rem]"
      :class="isOpen ? '-left-4' : '-left-full'"
    >
      <div class="border border-primary bg-white rounded-r-md overflow-clip">
        <div class="flex justify-between items-center bg-primary text-white py-2 px-4">
          <div>
            {{ __('job-filter.title') }}
          </div>
          <div>
            <button
              type="button"
              @click="close"
            >
              <span class="fa fa-times" />
            </button>
          </div>
        </div>
        <div class="space-y-4 p-4">
          <div>
            <div
              class="flex justify-between items-center py-2 px-4 bg-gray-100 cursor-pointer"
              @click="hoursOpen = !hoursOpen"
            >
              <div class="text-black font-medium">
                {{ __('job-filter.job-types.title') }}
              </div>
              <div>
                <div class="flex justify-center items-center bg-gray-400 h-5 w-5 text-white text-xs rounded-full">
                  <span
                    v-show="!hoursOpen"
                    class="fa fa-plus"
                  />
                  <span
                    v-show="hoursOpen"
                    class="fa fa-minus"
                  />
                </div>
              </div>
            </div>
            <div
              v-show="hoursOpen"
              class="px-4 py-2 border-2 border-t-0 border-gray-100 rounded rounded-t-none"
            >
              <div class="flex flex-col space-y-2 text-sm">
                <label class="flex items-center">
                  <input
                    v-model="$store.state.Index.jobSearch.jobTypes"
                    name="job_type_mobile"
                    type="radio"
                    :value="['FULL_TIME']"
                  >
                  {{ __('definitions.job-types.full_time') }}
                </label>
                <label class="flex items-center">
                  <input
                    v-model="$store.state.Index.jobSearch.jobTypes"
                    name="job_type_mobile"
                    type="radio"
                    :value="['PART_TIME']"
                  >
                  {{ __('definitions.job-types.part_time') }}
                </label>
                <label class="flex items-center">
                  <input
                    v-model="$store.state.Index.jobSearch.jobTypes"
                    name="job_type_mobile"
                    type="radio"
                    :value="[]"
                  >
                  {{ __('definitions.job-types.all') }}
                </label>
              </div>
            </div>
          </div>

          <div>
            <div
              class="flex justify-between items-center py-2 px-4 bg-gray-100 cursor-pointer"
              @click="distanceOpen = !distanceOpen"
            >
              <div class="text-black font-medium">
                {{ __('job-filter.filter-by.travel-distance.distance-input.label') }}
              </div>
              <div>
                <div class="flex justify-center items-center bg-gray-400 h-5 w-5 text-white text-xs rounded-full">
                  <span
                    v-show="!distanceOpen"
                    class="fa fa-plus"
                  />
                  <span
                    v-show="distanceOpen"
                    class="fa fa-minus"
                  />
                </div>
              </div>
            </div>
            <div
              v-show="distanceOpen"
              class="px-4 py-2 border-2 border-t-0 border-gray-100 rounded rounded-t-none"
            >
              <select
                v-model="$store.state.Index.jobSearch.location.radius"
                class="w-full focus:ring-primary focus:border-primary"
              >
                <template
                  v-for="distance in distances"
                  :key="`distance-${distance}`"
                >
                  <option :value="distance">
                    {{ __('definitions.distance', { distance }) }}
                  </option>
                </template>
              </select>
            </div>
          </div>

          <div>
            <div
              class="flex justify-between items-center py-2 px-4 bg-gray-100 cursor-pointer"
              @click="publishedSinceOpen = !publishedSinceOpen"
            >
              <div class="text-black font-medium">
                {{ __('job-filter.job-age') }}
              </div>
              <div>
                <div class="flex justify-center items-center bg-gray-400 h-5 w-5 text-white text-xs rounded-full">
                  <span
                    v-show="!publishedSinceOpen"
                    class="fa fa-plus"
                  />
                  <span
                    v-show="publishedSinceOpen"
                    class="fa fa-minus"
                  />
                </div>
              </div>
            </div>
            <div
              v-show="publishedSinceOpen"
              class="px-4 py-2 border-2 border-t-0 border-gray-100 rounded rounded-t-none"
            >
              <div class="flex flex-col space-y-2 text-sm">
                <template
                  v-for="age in ages"
                  :key="`age-${age}`"
                >
                  <label class="flex items-center">
                    <input
                      v-model="$store.state.Index.jobSearch.jobAge"
                      name="job_age_mobile"
                      type="radio"
                      :value="age"
                    >
                    {{ __('job-filter.published-at', { search_date: age }) }}
                  </label>
                </template>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="border border-controls hover:bg-controls text-controls hover:text-white transition-colors px-4 py-2 rounded w-full"
            @click="apply"
          >
            {{ __('job-filter.apply-button') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterMixin from './FilterMixin';

export default {
  mixins: [FilterMixin],

  data() {
    return {
      isOpen: false,

      hoursOpen: false,
      distanceOpen: false,
      publishedSinceOpen: false,
    };
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    apply() {
      this.close();
      this.search();
    },
  },
};
</script>
