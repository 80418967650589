const state = () => ({
  jobSearches: [],
});

const mutations = {
  setJobSearches(state, jobSearches) {
    state.jobSearches = jobSearches;
  },

  addJobSearch(state, { jobSearch, index }) {
    if (index) {
      state.jobSearches.splice(index + 1, 0, jobSearch);
    } else {
      state.jobSearches.push(jobSearch);
    }
  },

  removeJobSearch(state, index) {
    state.jobSearches.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
