<template>
  <InertiaHead :title="__('profile.title')" />

  <main class="container">
    <Unsubscribed />
    <OptInReminder />

    <div class="flex mt-8">
      <div class="hidden md:block w-1/4 space-y-6">
        <div class="rounded-md border border-gray-300 px-6 py-4 mb-8 font-semibold text-xl">
          {{ __('profile.title') }}
        </div>

        <div class="rounded-md border border-gray-300 divide-y divide-gray-300 overflow-clip">
          <InertiaLink
            :href="route('frontend.profile.job-alert-settings.index')"
            class="block px-6 py-3 transition-colors hover:no-underline"
            :class="[ isCurrent('frontend.profile.job-alert-settings.index') ? 'bg-primary text-white font-medium' : 'hover:bg-gray-100' ]"
          >
            {{ __('profile.menu.job-alert') }}
          </InertiaLink>
          <InertiaLink
            :href="route('frontend.profile.my-information.index')"
            class="block px-6 py-3 transition-colors hover:no-underline"
            :class="[ isCurrent('frontend.profile.my-information.index') ? 'bg-primary text-white font-medium' : 'hover:bg-gray-100' ]"
          >
            {{ __('profile.menu.information') }}
          </InertiaLink>
          <InertiaLink
            :href="route('frontend.profile.notifications.index')"
            class="block px-6 py-3 transition-colors hover:no-underline"
            :class="[ isCurrent('frontend.profile.notifications.index') ? 'bg-primary text-white font-medium' : 'hover:bg-gray-100' ]"
          >
            {{ __('profile.menu.notifications') }}
          </InertiaLink>
          <InertiaLink
            :href="route('frontend.profile.delete.index')"
            class="block px-6 py-3 transition-colors hover:no-underline"
            :class="[ isCurrent('frontend.profile.delete.index') ? 'bg-primary text-white font-medium' : 'hover:bg-gray-100' ]"
          >
            {{ __('profile.menu.delete') }}
          </InertiaLink>
          <InertiaLink
            :href="route('frontend.auth.logout')"
            method="post"
            as="button"
            class="block w-full text-left px-6 py-3 transition-colors hover:bg-gray-100"
          >
            {{ __('profile.menu.logout') }}
          </InertiaLink>
        </div>
      </div>

      <div class="w-full md:w-3/4 md:ml-8">
        <slot />
      </div>
    </div>
  </main>
</template>

<script>
import OptInReminder from '../Components/OptInReminder/OptInReminder.vue';
import Unsubscribed from '../Components/Unsubscribed.vue';

export default {
  components: {Unsubscribed, OptInReminder},
  methods: {
    isCurrent(route) {
      return this.route().current() === route;
    },
  },
};
</script>
