const state = () => ({
  firstName: '',
  lastName: '',
  email: '',
  mobilePhoneNumber: '',
  birthday: '',
});

const mutations = {
  setJobSeeker(state, jobSeeker) {
    for (const key in jobSeeker) {
      state[key] = jobSeeker[key];
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
