<template>
  <template v-if="showRatingOverlay">
    <div
      class="absolute top-0 left-0 h-full w-full bg-gray-200/95 p-4"
      :class="{ 'border-4 border-primary': showRatingOverlay && !highlighted }"
    >
      <div class="absolute top-4 right-4 text-2xl">
        <button
          type="button"
          @click="showRatingOverlay = false"
        >
          <span class="fa fa-times-circle" />
        </button>
      </div>

      <div class="text-center text-2xl font-semibold mb-4">
        {{ __(ratingTitle) }}
      </div>

      <div
        v-show="!hideRatingButtons"
        class="text-center text-6xl space-x-12"
      >
        <button
          type="button"
          class="text-success"
          @click="selectRating(10)"
        >
          <span class="fa fa-thumbs-up" />
        </button>
        <button
          type="button"
          class="text-danger"
          @click="selectRating(1)"
        >
          <span class="fa fa-thumbs-down" />
        </button>
      </div>

      <div
        v-show="ratingSurveyGood"
        class="flex flex-col items-center"
      >
        <label class="flex items-center">
          <input
            v-model="survey.answers"
            name="survey_good_applied"
            type="radio"
            :value="['APPLIED']"
            @change="submitSurvey(['APPLIED'])"
          >
          {{ __('job-detail.rating.survey.good.answers.yes') }}
        </label>

        <label class="flex items-center">
          <input
            v-model="survey.answers"
            name="survey_good_applied"
            type="radio"
            :value="['NOT_APPLIED']"
            @change="submitSurvey(['NOT_APPLIED'])"
          >
          {{ __('job-detail.rating.survey.good.answers.no') }}
        </label>
      </div>

      <div
        v-show="ratingSurveyBad"
        class="flex items-end"
      >
        <div class="grid grid-cols-2 w-full">
          <label class="flex items-center">
            <input
              v-model="survey.answers"
              type="checkbox"
              value="EXPIRED"
            >
            {{ __('job-detail.rating.survey.bad.answers.expired') }}
          </label>
          <label class="flex items-center">
            <input
              v-model="survey.answers"
              type="checkbox"
              value="NOT_VIEWABLE"
            >
            {{ __('job-detail.rating.survey.bad.answers.not-viewable') }}
          </label>
          <label class="flex items-center">
            <input
              v-model="survey.answers"
              type="checkbox"
              value="COULD_NOT_APPLY"
            >
            {{ __('job-detail.rating.survey.bad.answers.could-not-apply') }}
          </label>
          <label class="flex items-center">
            <input
              v-model="survey.answers"
              type="checkbox"
              value="WRONG_LOCATION"
            >
            {{ __('job-detail.rating.survey.bad.answers.wrong-location') }}
          </label>
          <label class="flex items-center">
            <input
              v-model="survey.answers"
              type="checkbox"
              value="IRRELEVANT"
            >
            {{ __('job-detail.rating.survey.bad.answers.irrelevant') }}
          </label>
          <label class="flex items-center">
            <input
              v-model="survey.answers"
              type="checkbox"
              value="FRAUD"
            >
            {{ __('job-detail.rating.survey.bad.answers.fraud') }}
          </label>
          <label class="flex items-center">
            <input
              v-model="survey.answers"
              type="checkbox"
              value="MANDATORY_REGISTRATION"
            >
            {{ __('job-detail.rating.survey.bad.answers.mandatory-registration') }}
          </label>

          <div>
            <template v-if="!survey.showOtherInput">
              <label class="flex items-center">
                <input
                  type="checkbox"
                  @change="enableShowOtherInput"
                >
                {{ __('job-detail.rating.survey.bad.answers.other') }}
              </label>
            </template>
            <template v-else>
              <input
                ref="other-input"
                v-model="survey.other"
                type="text"
                maxlength="200"
                class="form-input py-1 px-2"
                :placeholder="__('job-detail.rating.survey.bad.answers.other-placeholder')"
                @keyup="otherKeyUp"
              >
            </template>
          </div>
        </div>

        <div>
          <button
            type="button"
            class="w-full md:w-auto bg-success hover:bg-success/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap hover:no-underline"
            :disabled="!canSubmit"
            @click="submitSurvey()"
          >
            {{ __('job-detail.rating.survey.submit') }}
          </button>
        </div>
      </div>
    </div>
  </template>

  <ErrorModal ref="error-modal" />
</template>

<script>
import axios from 'axios';
import {merge} from 'lodash';
import ErrorModal from '../../../Auth/Components/ErrorModal.vue';

export default {
  components: {
    ErrorModal,
  },

  props: {
    job: {
      required: true,
      type: Object,
    },

    highlighted: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      jobRating: null,

      showRatingOverlay: false,
      ratingTitle: 'job-detail.rating.title',
      ratingSelected: false,
      hideRatingButtons: false,
      ratingSurveyGood: false,
      ratingSurveyBad: false,
      survey: {
        showOtherInput: false,
        answers: [],
        other: '',
      },
    };
  },

  computed: {
    canSubmit() {
      return this.survey.answers.length > 0 || this.survey.other !== '';
    },
  },

  mounted() {
    if (this.highlighted && this.$store.state.Index.jobRating) {
      this.selectRating(this.$store.state.Index.jobRating, 'mail');

      if (this.$store.state.Index.jobRating === 1) {
        this.showRatingOverlay = true;
      }
    }
  },

  methods: {
    openDelayed() {
      // Only show overlay if no rating was selected yet
      // Wait a second before showing the overlay
      if (!this.ratingSelected) {
        setTimeout(() => {
          this.showRatingOverlay = true;
        }, 1000);
      }
    },

    enableShowOtherInput() {
      this.survey.showOtherInput = true;

      this.$nextTick(() => {
        this.$refs['other-input'].focus();
      });
    },

    otherKeyUp(key) {
      if (key.code === 'Backspace' && this.survey.other === '') {
        this.survey.showOtherInput = false;
      }
    },

    selectRating(rating, type = 'serp') {
      this.ratingSelected = type !== 'mail';
      this.hideRatingButtons = true;

      this.ratingTitle = 'job-detail.rating.survey.' + (rating === 10 ? 'good' : 'bad') + '.title';
      this.ratingSurveyGood = rating === 10;
      this.ratingSurveyBad = rating === 1;

      const job = this.job;

      if (this.$page.props.jobSeeker && this.$page.props.jobSeeker.id) {
        job.jobSeekerId = this.$page.props.jobSeeker.id;
      }

      axios.post(this.route('frontend.api.jobs.rate'), {
        jobSeekerId: this.$page.props.jobSeeker?.id,
        rating,
        job,
        type,
      })
        .then(({data}) => {
          this.jobRating = data.jobRating;
        })
        .catch(() => {
          this.$refs['error-modal'].open();
        });
    },

    submitSurvey(answers = []) {
      this.ratingTitle = 'job-detail.rating.title-selected';
      this.ratingSurveyGood = false;
      this.ratingSurveyBad = false;

      answers = merge(answers, this.survey.answers);
      if (this.survey.other !== '') {
        answers.push(this.survey.other);
      }

      axios.post(this.route('frontend.api.jobs.survey'), {
        jobRating: this.jobRating,
        answers,
      })
        .catch(() => {
          this.$refs['error-modal'].open();
        });
    },
  },
};
</script>
