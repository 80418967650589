<template>
  <InertiaHead :title="__('post-a-job.title')" />
  <main class="content container md:px-12 mt-8 md:mt-18">
    <h1>
      {{ __('post-a-job.title') }}
    </h1>

    <hr class="my-4">

    <p>
      {{ __('post-a-job.section-1.text') }}
    </p>

    <a
      href="https://form.typeform.com/to/jcM8lmNX"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ __('post-a-job.section-1.link') }}
    </a>

    <hr class="my-4">

    <h2>
      {{ __('post-a-job.section-2.title') }}
    </h2>

    <p>
      {{ __('post-a-job.section-2.text') }}
    </p>

    <a
      href="https://form.typeform.com/to/jcM8lmNX"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ __('post-a-job.section-2.link') }}
    </a>
  </main>
</template>
