export default {
  methods: {
    jobSearchToQuery(jobSearch) {
      const query = {};
      query['query'] = jobSearch.keywords && jobSearch.keywords.length > 0 ? jobSearch.keywords.join(',') : null;
      query['city'] = jobSearch.location.city || null;
      query['state'] = jobSearch.location.state || null;
      query['country'] = jobSearch.location.country || 'US';
      query['postcode'] = jobSearch.location.postalCode || null;
      query['coordinates'] = jobSearch.location.coordinates || null;
      query['radius'] = jobSearch.location.radius || 40;
      query['age'] = jobSearch.jobAge || 14;
      if (jobSearch.jobTypes && jobSearch.jobTypes.length > 0) {
        query['type'] = jobSearch.jobTypes[0].toLowerCase();
      } else {
        query['type'] = null;
      }

      return query;
    },
  },
};
