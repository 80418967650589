<template>
  <slot
    name="button"
    :toggle="toggle"
  />

  <Teleport to="body">
    <div
      class="absolute top-16 transition-all duration-200 overflow-y-hidden w-screen"
      :class="isOpen ? 'max-h-80' : 'max-h-0'"
    >
      <div class="border-y border-gray-300 bg-white divide-y divide-gray-300">
        <InertiaLink
          :href="route('frontend.profile.job-alert-settings.index')"
          class="block px-6 py-3 bg-primary text-white font-medium hover:no-underline"
        >
          {{ __('profile.menu.job-alert') }}
        </InertiaLink>
        <InertiaLink
          :href="route('frontend.profile.my-information.index')"
          class="block px-6 py-3 hover:bg-gray-100 transition-colors hover:no-underline"
        >
          {{ __('profile.menu.information') }}
        </InertiaLink>
        <InertiaLink
          :href="route('frontend.profile.notifications.index')"
          class="block px-6 py-3 hover:bg-gray-100 transition-colors hover:no-underline"
        >
          {{ __('profile.menu.notifications') }}
        </InertiaLink>
        <InertiaLink
          :href="route('frontend.profile.delete.index')"
          class="block px-6 py-3 hover:bg-gray-100 transition-colors hover:no-underline"
        >
          {{ __('profile.menu.delete') }}
        </InertiaLink>
        <InertiaLink
          :href="route('frontend.index')"
          method="post"
          as="button"
          class="block px-6 py-3 hover:bg-gray-100 transition-colors hover:no-underline"
        >
          {{ __('profile.menu.logout') }}
        </InertiaLink>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
