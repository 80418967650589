<template>
  <div class="rounded-t-md border border-gray-300 p-4 md:p-8">
    <div class="font-semibold text-3xl mb-2">
      {{ __('profile.delete.title') }}
    </div>

    <div>
      {{ __('profile.delete.text') }}
    </div>
  </div>

  <div class="rounded-b-md border border-t-0 border-gray-300 px-4 md:px-8">
    <div class="py-4">
      <form @submit.prevent="confirmDeleteAccount">
        <button
          type="submit"
          class="border bg-danger hover:bg-danger/80 text-white transition-colors px-4 py-2 my-4 rounded whitespace-nowrap"
          @click="confirmDeleteAccount"
        >
          <span class="fa fa-times mr-1" />
          {{ __('profile.delete.button') }}
        </button>
      </form>

      <div class="border-t border-gray-200 mt-4 pt-4">
        {{ __('profile.delete.disable-text') }}
        <InertiaLink
          :href="route('frontend.profile.notifications.index')"
          class="text-primary hover:text-primary-dark"
        >
          {{ __('profile.delete.disable-link') }}
        </InertiaLink>
      </div>
    </div>
  </div>

  <ConfirmDeleteModal ref="confirm-delete-modal" />
</template>

<script>
import DefaultLayout from '../../../Layouts/Default.vue';
import ProfileLayout from '../../../Layouts/Profile.vue';
import ConfirmDeleteModal from './Components/ConfirmDeleteModal.vue';

export default {
  components: {
    ConfirmDeleteModal,
  },

  layout: [DefaultLayout, ProfileLayout],

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    confirmDeleteAccount() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$refs['confirm-delete-modal'].open({
        cancel: function() {
          this.loading = false;
        }.bind(this),
        delete: function() {
          return this.$inertia.post(this.route('frontend.profile.delete.update'), {}, {
            onError() {
              this.$refs['error-modal'].open();
            },
          });
        }.bind(this),
      });
    },
  },
};
</script>
