import {cloneDeep} from 'lodash';

const initialJobSearch = {
  keywords: [],
  location: {
    country: 'US',
    radius: 40,
  },
  jobAge: 14,
  jobTypes: [],
  maxJobAlertsSetting: {},
};

const state = () => ({
  jobSeeker: {
    email: '',
    country: 'US',
    locale: 'en-US',
    utm: {},
    gclid: null,
    ip: '',
    userAgent: '',
  },

  jobSearch: cloneDeep(initialJobSearch),

  selectedKeywords: [],
  customKeywords: [],
});

const mutations = {
  setRequestData(state, requestData) {
    state.jobSeeker.utm = requestData.utm;
    state.jobSeeker.ip = requestData.ip;
    state.jobSeeker.userAgent = requestData.userAgent;
    state.jobSeeker.country = requestData.country;
    state.jobSeeker.locale = requestData.locale;
    state.jobSeeker.gclid = requestData.gclid;

    state.jobSearch.location.country = requestData.country;
  },

  addSelectedKeyword(state, keyword) {
    state.selectedKeywords.push(keyword);
  },

  removeSelectedKeyword(state, keyword) {
    const index = state.selectedKeywords.indexOf(keyword);
    state.selectedKeywords.splice(index, 1);
  },

  clearJobSearch(state) {
    state.jobSearch = initialJobSearch;

    state.selectedKeywords = [];
    state.customKeywords = [];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
