<template>
  <main class="container mt-12 md:mt-16">
    <div class="text-center">
      <h2 class="font-bold text-2xl md:text-3xl mb-8">
        {{ __('delay.header') }}
      </h2>

      <h1 class="font-medium text-3xl md:text-4xl mb-8 md:mb-12">
        <template v-if="job.title">
          {{ job.title }}
        </template>
        <template v-else>
          {{ __('delay.title') }}
        </template>
      </h1>

      <button
        type="button"
        class="bg-success hover:bg-success/80 disabled:bg-success/40 text-white transition-colors px-4 md:px-8 py-2 md:py-4 text-lg md:text-xl rounded mb-8 md:mb-12"
        tabindex="0"
        :disabled="tracking"
        @click="buttonClick"
      >
        {{ __('delay.link') }}
      </button>

      <div class="w-3/4 md:w-1/2 bg-gray-200 rounded-md h-4 dark:bg-gray-700 mb-8 mx-auto">
        <div class="bg-primary h-4 rounded-md animate-loader" />
      </div>

      <img
        :src="asset('logo.svg')"
        :alt="$page.props.config.brandName"
        class="h-10 mx-auto"
      >
    </div>
  </main>
</template>

<script>
import { useStore } from 'vuex';
import axios from 'axios';
import Search from '../../../../Mixins/Search';

export default {
  mixins: [Search],

  setup() {
    return {
      job: useStore().state.Index.job,
      jobSearch: useStore().state.Index.jobSearch,
    };
  },

  data() {
    return {
      mounted: false,
      duration: 6000, // ms

      tracking: false,
      trackingTimeout: null,
    };
  },

  computed: {
    url() {
      return this.route('frontend.out', {
        url: btoa(this.job.url),
      });
    },
  },

  mounted() {
    this.trackingTimeout = setTimeout(() => {
      if (this.tracking) {
        return;
      }

      this.tracking = true;

      const currentQuery = new URLSearchParams(location.search);
      const trackClickData = {
        job_seeker_id: this.$page.props.jobSeeker.id,
        job_search_id: this.jobSearch.id,
        job_id: currentQuery.get('job'),

        jobSearch: this.jobSearch,
        job: this.job,
      };

      currentQuery.forEach((value, key) => {
        if (key && key.startsWith('utm_')) {
          trackClickData[key] = value;
        }
      });

      axios.post(this.route('frontend.api.track.click'), trackClickData).finally(() => {
        window.location = this.url;
      });
    }, this.duration);
  },

  methods: {
    buttonClick() {
      if (this.tracking) {
        return;
      }

      this.tracking = true;

      if (this.trackingTimeout) {
        clearTimeout(this.trackingTimeout);
      }

      window.open(this.url);

      const currentQuery = new URLSearchParams(location.search);
      const trackClickData = {
        job_seeker_id: this.$page.props.jobSeeker.id,
        job_search_id: this.jobSearch.id,
        job_id: currentQuery.get('job'),

        jobSearch: this.jobSearch,
        job: this.job,
      };

      currentQuery.forEach((value, key) => {
        if (key && key.startsWith('utm_')) {
          trackClickData[key] = value;
        }
      });

      const query = this.jobSearchToQuery(this.jobSearch);
      if (currentQuery.has('job')) {
        query.job = currentQuery.get('job');
      }

      axios.post(this.route('frontend.api.track.click'), trackClickData).finally(() => {
        this.$inertia.visit(this.route('frontend.jobs', query));
      });
    },
  },
};
</script>
