const state = () => ({
  job: null,

  jobSearch: {
    keywords: [],
    location: {},
    jobAge: 14,
    jobTypes: [],
  },

  jobRating: null,
});

const mutations = {
  setJob(state, job) {
    state.job = job;
  },

  setJobSearch(state, jobSearch) {
    for (const key in jobSearch) {
      state.jobSearch[key] = jobSearch[key];
    }
  },

  setJobRating(state, jobRating) {
    state.jobRating = jobRating;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
