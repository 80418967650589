<template>
  <div class="items-center mt-6">
    <VueRecaptcha
      ref="recaptcha"
      :sitekey="$page.props.recaptcha.siteKey"
      action="button"
      class="w-fit mx-auto"
      @verify="onCaptchaVerified"
      @expired="resetCaptcha"
    />
  </div>
</template>

<script>
import {VueRecaptcha} from 'vue-recaptcha';

export default {
  components: {VueRecaptcha},

  emits: ['success'],

  methods: {
    formSubmit() {
      this.$refs.recaptcha.execute();
    },

    onCaptchaVerified(token) {
      this.$emit('success', token);
    },

    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

