export default {
  emits: ['search'],

  data() {
    return {
      distances: [
        3,
        5,
        10,
        15,
        20,
        30,
        40,
        50,
        100,
      ],

      ages: [
        3,
        7,
        14,
        30,
        60,
        90,
      ],

      maxJobAlerts: [
        {
          name: this.__('profile.job-alerts.add.max-job-alerts-settings.option.unlimited'),
          value: {},
        },
        {
          name: this.__('profile.job-alerts.add.max-job-alerts-settings.option.plural', {
            amount: 3,
            period: this.__('profile.job-alerts.add.max-job-alerts-settings.option.day'),
          }),
          value: { value: 3, type: 'DAILY' },
        },
        {
          name: this.__('profile.job-alerts.add.max-job-alerts-settings.option.plural', {
            amount: 2,
            period: this.__('profile.job-alerts.add.max-job-alerts-settings.option.day'),
          }),
          value: { value: 2, type: 'DAILY' },
        },
        {
          name: this.__('profile.job-alerts.add.max-job-alerts-settings.option.singular', {
            amount: 1,
            period: this.__('profile.job-alerts.add.max-job-alerts-settings.option.day'),
          }),
          value: { value: 1, type: 'DAILY' },
        },
        {
          name: this.__('profile.job-alerts.add.max-job-alerts-settings.option.singular', {
            amount: 1,
            period: this.__('profile.job-alerts.add.max-job-alerts-settings.option.week'),
          }),
          value: { value: 1, type: 'WEEKLY' },
        },
      ],
    };
  },

  methods: {
    search() {
      this.$emit('search');
    },
  },
};
