<template>
  <select
    v-model="year"
    class="grow"
  >
    <option
      :value="null"
      selected
    >
      {{ __('profile.account-details.change-date-of-birth.year-placeholder') }}
    </option>
    <template
      v-for="availableYear in availableBirthdayYears"
      :key="`available-year-${availableYear}`"
    >
      <option :value="availableYear">
        {{ availableYear }}
      </option>
    </template>
  </select>
  <select
    v-model="month"
    class="grow"
  >
    <option
      :value="null"
      selected
    >
      {{ __('profile.account-details.change-date-of-birth.month-placeholder') }}
    </option>
    <template
      v-for="(availableMonth, i) in availableBirthdayMonths"
      :key="`available-month-${availableMonth}`"
    >
      <option :value="i + 1">
        {{ availableMonth }}
      </option>
    </template>
  </select>
  <select
    v-model="day"
    class="grow"
  >
    <option
      :value="null"
      selected
    >
      {{ __('profile.account-details.change-date-of-birth.day-placeholder') }}
    </option>
    <template
      v-for="availableDay in availableBirthdayDays"
      :key="`available-day-${availableDay}`"
    >
      <option :value="availableDay">
        {{ availableDay }}
      </option>
    </template>
  </select>
</template>

<script>
import moment from 'moment/moment';
import { range } from 'lodash';

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      year: null,
      month: null,
      day: null,

      availableBirthdayDays: [],
    };
  },

  computed: {
    availableBirthdayYears() {
      const year = new Date().getFullYear();
      return Array.from({ length: year - 1900 }, (value, index) => 1901 + index).reverse();
    },

    availableBirthdayMonths() {
      return moment.localeData().months();
    },

    birthdayYearMonth() {
      return `${this.year}-${this.month}`;
    },

    birthday() {
      return `${this.year}-${this.month}-${this.day}`;
    },
  },

  watch: {
    birthdayYearMonth: {
      immediate: true,
      handler: function(birthdayYearMonth) {
        if (this.year === null || this.month === null) {
          this.day = null;

          return [];
        }

        const daysInMonth = moment(birthdayYearMonth, 'YYYY-MM').daysInMonth();
        this.availableBirthdayDays = range(1, daysInMonth + 1);

        if (!this.availableBirthdayDays.includes(this.day)) {
          this.day = null;
        }
      },
    },

    birthday: {
      handler: function() {
        let birthday = null;
        if (this.year !== null && this.month !== null && this.day !== null) {
          birthday = moment.utc(
            `${this.year}-${this.month}-${this.day}`,
            'YYYY-MM-DD',
          ).format();
        }

        this.$emit('update:modelValue', birthday);
      },
    },
  },

  mounted() {
    if (this.modelValue && this.modelValue.length > 0) {
      const parsedBirthday = moment.utc(this.modelValue);
      if (parsedBirthday) {
        this.year = parsedBirthday.year();
        this.month = parsedBirthday.month() + 1;
        this.day = parsedBirthday.date();
      }
    }
  },
};
</script>
