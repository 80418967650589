import locationData from '../Data/location-data.json';

export default {
  methods: {
    getCountry(country) {
      if (!country) {
        return country;
      }

      country = country.toUpperCase();
      const location = locationData.find((location) => location.iso === country);

      return location ? location.name : country;
    },

    getStateByCountry(country, isoState) {
      if (!country || !isoState) {
        return isoState;
      }

      country = country.toUpperCase();
      const location = locationData.find((location) => location.iso === country);

      if (!location || !location.states) {
        return isoState;
      }

      isoState = isoState.toUpperCase();
      const state = location.states.find((state) => state.iso === isoState);

      return state ? state.name : isoState;
    },
  },
};
