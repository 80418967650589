<template>
  <div
    :data-type="highlighted ? 'highlighted-job' : 'job'"
    class="relative flex flex-col md:flex-row justify-between px-2 py-4 md:py-8 md:px-4"
    :class="[
      `job-${index}`,
      highlighted ? 'border-4 border-success' : ''
    ]"
  >
    <JobRating
      ref="rating"
      :job="job"
      :highlighted="highlighted"
    />

    <div class="flex flex-col">
      <a
        :href="outLink"
        target="_blank"
        rel="nofollow"
        class="block text-primary hover:text-primary-dark transition-colors underline hover:no-underline text-xl mb-1"
        @click="trackClick"
      >
        {{ job.title }}
      </a>

      <a
        :href="outLink"
        target="_blank"
        rel="nofollow"
        class="block text-black hover:text-primary-dark transition-colors hover:no-underline mb-2"
        @click="trackClick"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="job.description" />
        <!-- eslint-enable -->
      </a>

      <div class="space-y-1 md:space-y-0 text-gray-400">
        <div
          class="block md:inline-block w-full md:w-1/3 pr-2 truncate"
          :title="__('job-detail.location')"
        >
          <span class="fal fa-map-marker-alt" />
          {{ formattedLocation }}
        </div>
        <div
          class="block md:inline-block w-full md:w-1/3 pr-2 truncate"
          :title="__('job-detail.published')"
        >
          <span class="fal fa-calendar-alt" />
          {{ formattedDate }}
        </div>
        <div
          class="block md:inline-block w-full md:w-1/3 pr-2 truncate"
          :title="__('job-detail.company')"
        >
          <span class="fal fa-suitcase" />
          {{ job.company }}
        </div>
      </div>
    </div>

    <div class="mt-4 md:mt-0 md:ml-4">
      <a
        :href="outLink"
        rel="nofollow"
        target="_blank"
        class="w-full md:w-auto bg-success hover:bg-success/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap hover:no-underline"
        @click="trackClick"
      >
        {{ __('job-detail.apply') }}
      </a>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import JobRating from './JobRating.vue';

export default {
  components: {
    JobRating,
  },

  props: {
    job: {
      required: true,
      type: Object,
    },

    index: {
      required: true,
      type: Number,
    },

    highlighted: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formattedLocation() {
      if (this.job.location.postalCode) {
        return this.job.location.postalCode;
      } else if (this.job.location.city) {
        return this.job.location.city;
      } else if (this.job.location.country) {
        return this.job.location.country.toUpperCase();
      }

      return null;
    },

    formattedDate() {
      if (Date.now() - 25*24*60*60*1000 < this.job.date) {
        return moment(parseInt(this.job.date, 10)).fromNow();
      }

      return '25+ days ago';
    },

    outLink() {
      return this.route('frontend.out', {
        url: btoa(this.job.url),
      });
    },
  },

  methods: {
    trackClick() {
      const jobSearch = this.$store.state?.Index?.jobSearch || this.$page.props.jobSearch || {};

      const trackClickData = {
        job_seeker_id: this.$page.props.jobSeeker.id,
        job_search_id: jobSearch?.id,
        job_id: this.job?.id || null,

        jobSearch: jobSearch,
        job: this.job,
      };

      const currentQuery = new URLSearchParams(location.search);
      currentQuery.forEach((value, key) => {
        if (key && key.startsWith('utm_')) {
          trackClickData[key] = value;
        }
      });

      axios.post(this.route('frontend.api.track.click'), trackClickData).finally(() => {
        this.$tracker.jobClick(this.job);

        this.$refs['rating'].openDelayed();
      });
    },
  },
};
</script>
