<template>
  <Modal :open="isOpen">
    <template #content>
      <div>
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-success/40 text-success text-2xl">
          <span class="fa fa-check" />
        </div>

        <div class="mt-8 text-center">
          <h3 class="text-lg font-semibold leading-6 text-gray-900">
            {{ __('modals.contact-ticket-created-modal.title') }}
          </h3>

          <div class="mt-2 text-gray-500">
            {{ __('modals.contact-ticket-created-modal.text') }}
          </div>
        </div>
      </div>

      <div class="mt-8 text-center">
        <button
          type="button"
          class="w-full md:w-32 bg-controls hover:bg-controls/80 text-white transition-colors px-4 py-2 rounded whitespace-nowrap"
          @click="isOpen = false"
        >
          {{ __('modals.contact-ticket-created-modal.button') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { ref } from 'vue';
import Modal from '../../../Components/Modal.vue';

export default {
  components: {
    Modal,
  },

  props: {
    initialOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    return {
      isOpen: ref(props.initialOpen),
    };
  },

  methods: {
    open() {
      this.isOpen = true;
    },
  },
};
</script>
